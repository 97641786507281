.contactPageContainer {
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
}
.contactHeader {
  font-weight: 600 !important;
}
.aboutDetail {
  line-height: 28px !important;
  margin-bottom: 2px !important;
  padding: 0px 0%;
  width: 100%;
  text-align: center;
}
.contactImage {
  display: flex;
  width: 100%;
  height: 50vh;
  background-image: url(../assets/contact/contactUs.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cards {
  display: flex;
  justify-content: center;
  margin: 30px 0px;
  padding: 0px 10%;
}
@media (max-width: 1000px) {
  .cards {
    flex-direction: column;
    align-items: center;
    padding: 0px 5%;
  }
}
.contactForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10%;
}
.contactFormContainer {
  box-shadow: 0px 4px 15px #eeeeee;
  padding: 47px 57px 72px 75px;
  width: 50%;
}
.contactFormText {
  padding: 3px 57px 20px 75px;
  width: 50%;
}
.contactFormHeader {
  font-size: 30px !important;
  align-self: center;
  text-align: center;
  margin-bottom: 30px !important;
}

.contactFormContainer1 {
  display: flex;
  flex-direction: column;
}
.contactFormRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}
.contactFormInput {
  width: 48%;
  background-color: #f1f6fc !important;
}
.contactFormInputMessage {
  background-color: #f1f6fc !important;
}
.contactCard {
  box-shadow: 0px 4px 15px #eeeeee;
  padding: 47px 57px 72px 75px;
  width: 380px;
  margin-right: 30px;
}
.contactCardHead {
  line-height: 29px !important;

  margin-bottom: 5px !important;
  font-size: 30px !important;
}
.formNote {
  line-height: 28px !important;
  margin-bottom: 32px !important;
  padding: 0px 3%;
}
@media (max-width: 773px) {
  .box6Style {
    display: normal;
  }
  .aboutDetail {
    width: 100%;
  }
  .contactCard {
    width: 100%;
    padding: 47px 3% 72px 3%;
    margin-right: 0px;
    margin-top: 10px;
  }
  .contactCardHead {
    text-align: center;
  }
  .contactCardContent {
    text-align: center;
    padding: 0px 14%;
  }
  .contactFormRow {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    width: 100%;
  }
  .MuiFormControl-root {
    margin-bottom: 10px !important;
  }
  .contactFormInput {
    width: 100%;
    margin-bottom: 10px;
  }
  .contactFormContainer {
    padding: 47px 3% 72px 3%;
    width: 70%;
  }
  .contactFormText {
    padding: 47px 3% 20px 3%;
    width: 70%;
  }
  .contactFormHeader {
    font-size: 20px !important;
  }
  .wordBreak {
    word-break: break-word;
  }
  .formNote {
    text-align: center;
  }
  .contactImage {
    /* background: none !important; */
  }
}
.letsTalkContainer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
