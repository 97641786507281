#root {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
}

.box1Style {
  position: relative;
  padding: 75px 10%;

  background-color: rgba(49, 63, 133, 0.1);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.backVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-image: url(../assets/home.jpg);
  object-fit: cover;
  z-index: -1;
}
@media (max-width: 779px) {
  .backVideo {
    z-index: 0;
  }
}
.box1StyleDashboard {
  padding: 75px 10%;
  /* background-image: url(../assets/background1.svg); */
  /* background-color: rgba(0, 0, 0, 0.09); */
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  opacity: 1;
  background-blend-mode: multiply;
}
.box1Heading {
  margin-bottom: 31px !important;
  line-height: 75px !important;
  color: #fff;
  opacity: 1;
}
.loader {
  color: #f26723;
  text-align: center;
}
@media (min-width: 1600px) {
  .loader {
    display: none;
  }
}

.box1Heading1 {
  margin-bottom: 35px !important;
  line-height: 28px !important;
  color: #fff !important;
}

@media (max-width: 470px) {
  .box1Style {
    box-sizing: border-box;
    min-height: 50vh;
    width: 100%;
    /* background: none !important; */
    background-color: rgb(238, 243, 250) !important;
  }
}

.contentContainer {
  width: 30%;
  transform: translate(70%, 0);
  opacity: 1;
  transform: scale(0.2);
  transition: opacity 2s ease, transform 2s ease;
}
.slide-vertically {
  scroll-snap-type: y proximity;
  overflow-y: scroll;
  height: 100vh;
}

.slide-vertically > div {
  height: 100vh;
  scroll-snap-align: start;
  transition: transform 0.5s ease-in-out;
}

.slide-vertically.slide-vertically-active > div {
  transform: translateY(0);
}

.slide-vertically.slide-vertically-inactive > div {
  transform: translateY(100%);
}
.zoom-in-active {
  opacity: 1;
  transform: scale(1);
}
.dashboardContentContainer {
  width: -webkit-fill-available;
}
.groupButton {
  margin-bottom: 10px;
}
.sheet1 {
  opacity: 0.8;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fill);
}
.sheet2 {
  width: 100%;
  opacity: 1;
  /* box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.1); */
  /* z-index: 20; */
}
.sheet2annotationHeader {
  background-color: rgba(0, 0, 0, 1);
  color: rgb(238, 243, 250);
}
.sheet2otherTablesHeader {
  background-color: rgba(0, 0, 0, 0.6);
  color: rgb(238, 243, 250);
}
.otherColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sheet2-1 {
  background-color: rgba(106, 168, 79, 0.7);
}
.sheet2-2 {
  background-color: rgba(255, 242, 204, 0.7);
}
.sheet2-3 {
  background-color: rgba(224, 102, 102, 0.7);
}
.sheet2r5 {
  background-color: #6aa84f;
}

.sheet2r4 {
  background-color: #b6d7a8;
}
.sheet2r3 {
  background-color: #fff2cc;
}
.sheet2r2 {
  background-color: #f4cccc;
}
.sheet2r1 {
  background-color: #e66666;
}
.sheet3-4 {
  background-color: rgba(255, 0, 0, 1);
}
.sheet3-3 {
  background-color: rgba(255, 173, 51, 1);
}
.sheet3-2 {
  background-color: rgba(64, 171, 70, 1);
}
.sheet3-1 {
  background-color: rgba(173, 51, 173, 1);
}
.sheet3header {
  background-color: rgba(92, 133, 214, 1);
}
.sheet2otherTables {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 5px;
}
.sheet3 {
  width: 100%;
  opacity: 0.8;
  /* box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.1); */
  z-index: 20;
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin-bottom: 50px;
}
.graphWithLegend {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.legendStyle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 5px;
}
.StudentDashboard {
  width: 100%;
  height: 100vh;
  border: none;
}
#doc-title {
  display: none;
}
.annotationTables {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.sheet3otherTables {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5px;
}

.tableBorder {
  border: 1px solid grey;
  border-collapse: collapse;

  width: -webkit-fill-available;
}

.boldText {
  font-weight: bold;
}
th,
td {
  border: 1px solid grey;
  text-align: center;
  padding: 3px;
}
.table2 {
  overflow-x: auto;
  margin: 5px 3px;
}
.table2 .tableBorder th,
.table2 .tableBorder td {
  border: 1px dashed grey;
  font-size: 12px;
}
.table710 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
.tableBackground {
}
.tableBorder.tableBackground td {
  background-color: rgba(207, 226, 243, 1);
}
@media (max-width: 600px) {
  .sheet1 {
    grid-template-columns: repeat(1, 1fr);
  }
  .sheet3 {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 10px;
  }
  .legendStyle {
    flex-wrap: wrap;
  }
  .sheet3otherTables {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 5px;
    row-gap: 5px;
  }
  .sheet2otherTables {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 5px;
  }
  .annotationTables {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 5px;
  }
}
@media (max-width: 1481px) {
  .contentContainer {
    width: 55%;
    transform: translate(0, 0);
  }
}
@media (max-width: 891px) {
  .contentContainer {
    width: 90%;
    transform: translate(0, 0);
  }
}

.box2Style {
  padding: 0px 10%;
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
}
.box2StyleVariant {
  padding: 0px 10%;
  background-color: #ffffff;
  min-height: 10vh;
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
}
.box2Heading {
  color: #f26723 !important;
  text-transform: uppercase;
  font-weight: 600 !important;
  text-align: center;
}
.box2Heading1 {
  /* width: 80%; */
  line-height: 52px !important;
  margin-bottom: 21px !important;
  text-align: center;
}
.box2ReadMore {
  height: inherit !important;
  max-width: 100px !important;
  align-self: center;
}
.box7ReadMore {
  height: inherit !important;
}
.box3Grid {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
.profile {
  display: flex;
  width: 40%;
  align-items: center;
}
.profileContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media (max-width: 773px) {
  .box1Heading {
    margin-bottom: 1px !important;
    line-height: 60px !important;
    text-align: center;
    margin-top: 5% !important;
  }
  .box1Heading1 {
    text-align: center;
  }
  .box2Style {
    box-sizing: border-box;

    width: 100%;
  }
  .box2StyleVariant {
    box-sizing: border-box;

    width: 100%;
  }
  .box2ReadMore {
    align-self: center;
    width: 100%;
  }
  .profile {
    display: none;
  }
  .profileContent {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .box2Heading {
    text-align: center;
  }
  .box2Heading1 {
    width: 100%;
    text-align: center;
  }
}
.box3Style {
  padding: 7% 10%;
  background-image: url(../assets/background3.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}
.box3HeaderContainer {
  width: 70%;
  text-align: center;
  margin-bottom: 32px;
}
.box3Title {
  color: #f26723 !important;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 13px;
}
.box3Header {
  line-height: 52px !important;
  margin-bottom: 9px !important;
  word-break: break-word;
}
.circle {
  position: relative;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: space-between;
  margin: 30px 0px;
  padding: 0px 0%;
}

.rowContainer {
  display: flex;
  flex-direction: row !important;
  /* text-align: center; */
  margin-bottom: 45px;
  cursor: pointer;
  opacity: 0;
  transform: translateX(1px);
  transition: opacity 2s ease, transform 2s ease;
}
.rowContainer2 {
  display: flex;
  flex-direction: row !important;
  margin-bottom: 45px;
  justify-content: center;
}
.rowImage {
  /* align-self: flex-start; */
  width: 40px;
  height: 36px;
  margin-right: 19px;
}
.rowContent {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.rowContentBody {
  overflow: hidden;
  line-height: 28px !important;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  /* text-overflow: "ellipsis";
  overflow: "hidden";
  white-space:  "nowrap", */
}

@media (max-width: 773px) {
  .box3Style {
    box-sizing: border-box;
    margin-top: 10px;
    width: 100vw;
    /* background: none !important; */
    background-color: rgb(238, 243, 250) !important;
  }
  .box3HeaderContainer {
    width: 100%;
  }
  .box3Grid {
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: 1fr;
  }
  .rowContainer {
    flex-direction: column !important;
    margin-bottom: 0px;
  }
  .rowContent {
    width: 100%;
  }
}

.box4Style {
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.contentPoint {
  display: flex;
  flex-direction: row;
  width: inherit;

  opacity: 0;
  transform: translateX(0%);
  transition: opacity 2s ease, transform 2s ease-in-out;
}
.zoom-points {
  opacity: 1;
  transform: translate(0%);
}
.imgBox {
  display: flex;
  flex: 1;
}
.imgContainer {
  position: relative;
  top: 112px;
}
.img1 {
  position: absolute;
  left: 196px;
}
.img2 {
  position: absolute;
  top: 28px;
  left: 577px;
  z-index: 1;
}
.img3 {
  position: absolute;
  top: 28px;
  left: 499px;
  z-index: 1;
}
.img4 {
  position: absolute;
  left: 150px;
}
.img5 {
  position: absolute;
  top: 176px;
  z-index: 2;
  left: 346px;
}
.img6 {
  position: absolute;
  top: 207px;
  left: 325px;
  z-index: 1;
}
.img7 {
  position: absolute;
  top: 228px;
  left: 300px;
}
.imgContainer-2 {
  position: relative;
  top: 112px;
}
.img1-2 {
  position: absolute;
  left: 196px;
}
.img2-2 {
  position: absolute;
  top: 28px;
  left: 577px;
  z-index: 1;
}
.img3-2 {
  position: absolute;
  top: 28px;
  left: 499px;
  z-index: 1;
}
.img4-2 {
  position: absolute;
  left: 150px;
}
.img5-2 {
  position: absolute;
  top: 176px;
  z-index: 2;
  left: 346px;
}
.img6-2 {
  position: absolute;
  top: 207px;
  left: 325px;
  z-index: 1;
}
.img7-2 {
  position: absolute;
  top: 228px;
  left: 300px;
}
.firstPoint {
  margin-bottom: 24px;
  width: 70%;
}

.secondPoint {
  margin-left: 65px;
  margin-bottom: 32px;
  width: 60%;
}
.thirdPoint {
  margin-left: 65px;
  width: 50%;
}
@media (max-width: 1312px) {
  .box4Style {
    display: normal;
    justify-content: center;
    padding-left: 10%;
    width: -webkit-fill-available;
  }
  .imgBox {
    display: none;
  }
  .firstPoint,
  .secondPoint,
  .thirdPoint {
    margin-left: 0px;
    margin-right: 0px;
    width: 90%;
  }
}
.box5Style {
  padding: 10% 10%;
  background-image: url(../assets/background5.png);
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.3);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 773px) {
  .box5Style {
    padding: 0;
    width: 100vw;
    /* background: none !important; */
    background-color: rgb(238, 243, 250) !important;
  }
  .contentPoint {
    transform: translateX(0%);
    transition: opacity 2s ease;
  }
}
.box6Style {
  padding: 0% 10%;
  display: flex;
  justify-content: center;
}
.box6StyleVariant {
  padding: 0% 0%;
  display: flex;
  flex-direction: column;
}
.box6Content {
  position: relative;
  top: -10px;
  align-self: center;
  box-shadow: 23px 23px 72px #474545;
  /* padding: 47px 57px 72px 75px; */
  height: 60vh;
  width: 70vh;
  border-radius: 50px;
  margin-top: 20px;
  margin-bottom: 26px;
}
@media (max-width: 773px) {
  .box6Style {
    display: normal;
  }
  .box6Content {
    width: 90vw;
  }
}

.box7Style {
  background-image: url(../assets/footer.png);
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: cover;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.box7Project {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  width: 100%;
  background-color: #173969;
  justify-content: space-evenly;
  align-items: center;
  padding: 3% 3% !important;
  margin-bottom: 0%;
}
.box7Heading {
  color: #ffffff;
  word-break: break-word;
}
.box7Heading1 {
  line-height: 25px !important;
  color: #ffffff !important;
  margin-top: 20px !important;
  word-break: break-word;
}
.button {
  margin-top: 30px;
  align-self: flex-start;
}
.box8Column {
  display: flex;
  flex-direction: column;
  max-width: 60%;
}
.box8ContactHeader {
  text-transform: uppercase;
  margin: 30px 0px 30px 0px !important;
  align-self: center;
}
.box8ContactContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 20px;
}
.contactUsRowView {
  display: flex;
  justify-content: space-between;
}
.contactUsRowViewSideMenu {
}
.box8SocialContact {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.contactUsSocial {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.socialIcons {
  display: flex;
  align-items: center;
}
.box8ContactIconContainer {
  border-radius: 100px;
  padding: 11px;
  height: 18px;
  background: #fff;
  margin-right: 18px;
}
.projectContainer {
  width: "50%";
}
.contactContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
@media (max-width: 988px) {
  .box7Project {
    flex-direction: column;
    padding: 10% !important;
    text-align: center;
  }
  .button {
    margin-top: 30px;
    align-self: center;
    width: 100%;
  }
}
@media (max-width: 773px) {
  .box7Style {
    width: 100vw;
    background: none !important;
    background-color: rgb(238, 243, 250) !important;
  }

  .projectContainer {
    width: "100%";
  }

  .contactContainer {
    flex-direction: column;
    padding: 10%;
  }
  .contactWidth {
    width: 100%;
  }
  .box8Column {
    align-items: center;
    align-self: center;
    min-width: 100%;
  }
  .box8ContactContainer {
    width: 100%;
    margin-bottom: 3px;
  }
}
@media (max-width: 1818px) {
  .contactUsRowView {
    flex-direction: column;
  }
}
.responsive {
  min-height: 700px;
  min-width: 700px;
  position: relative;
}
.responsive-2 {
  width: 246px;
  align-self: center;
  min-height: 246px;
  margin-right: 3px;
  position: relative;
}
.responsiveContent {
  width: 80%;
}
.aboutMe {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1390px) {
  .circle {
    flex-direction: column;
  }
  .responsiveContent {
    width: 100%;
  }
  /* .responsive-2 {
    display: none;
  } */
}

@media (max-width: 1700px) {
  .responsive {
    display: none;
  }
}
@media (max-height: 760px) {
  .responsive {
    display: none;
  }
  /* .responsive-2 {
    display: none;
  } */
}
.imgA1 {
  position: absolute;
  top: 17px;
  left: 35px;
}
.imageA1 {
  height: 70%;
  width: 70%;
  transform: scale(0.65);
}
.imgA2 {
  position: absolute;
  top: 46px;
  left: 71px;
  z-index: 1;
  animation: topleft 5s infinite;
}
.imageA2 {
  height: 70%;
  width: 70%;
  transform: scale(0.9);
}
.imgA3 {
  position: absolute;
  top: 46px;
  right: 137px;
  z-index: 1;
  animation: topleft 5s infinite;
}
.imageA3 {
  height: 70%;
  width: 70%;
  transform: scale(0.9);
}
.imgA3-1a {
  position: absolute;
  bottom: 336px;
  right: 89px;
  z-index: 1;
  animation: topleft 3s infinite;
}
.imageA3-1a {
  height: 70%;
  width: 70%;
  transform: scale(0.5);
}

.imgA4 {
  /* background-color: yellow; */
  position: absolute;
  top: 74px;
  left: 35px;
  z-index: 1;
  animation: topleft 5s infinite;
}
.imgA4-2 {
  /* background-color: yellow; */
  position: absolute;
  bottom: 236px;
  right: 186px;
  z-index: 1;
  animation: topleft 5s infinite;
}
.imageA4 {
  height: 70%;
  width: 70%;
  transform: scale(0.65);
}
.imgA4-1a {
  position: absolute;
  top: 18px;
  left: 170px;
  z-index: 1;
  animation: topleft 3s infinite;
}
.imageA4-1a {
  height: 70%;
  width: 70%;
  transform: scale(0.3);
}
.imgA4-1b {
  position: absolute;
  bottom: 236px;
  left: 170px;
  z-index: 1;
  animation: topleft 3s infinite;
}
.imgA4-2a {
  position: absolute;
  bottom: 236px;
  left: 90px;
  z-index: 1;
  animation: topleft 5s infinite;
}
.imageA4-2a {
  height: 70%;
  width: 70%;
  transform: scale(0.2);
}
.imgA4-2b {
  position: absolute;
  top: 84px;
  right: 170px;
  z-index: 1;
  animation: topleft 5s infinite;
}
.imgA4-3a {
  position: absolute;
  top: 0px;
  left: 251px;
  z-index: 1;
  animation: topleft 3s infinite;
}
.imageA4-3a {
  height: 70%;
  width: 70%;
  transform: scale(0.35);
}
.imgA4-3b {
  position: absolute;
  top: 169px;
  right: 118px;
  z-index: 1;
  animation: topleft 3s infinite;
}

.imgA5 {
  position: absolute;
  top: 58px;
  left: 81px;
  width: 79%;
  height: 79%;
}
.imageA5 {
  height: 70%;
  width: 70%;
  animation: mymove 5s infinite;
}
.imgA6 {
  position: absolute;
  top: 84px;
  left: 104px;
  width: 100%;
  height: inherit;
}
.imageA6 {
  height: 48%;
  width: 48%;
  animation: mymove 5s infinite;
}
.imgB1-2 {
  position: absolute;
  top: 17px;
  left: 35px;
}
.imageB1-2 {
  position: absolute;
  transform: scale(0.8);
  height: 100%;
  width: 100%;
}
.imgB2-2 {
  position: absolute;
  top: -32px;
  left: -57px;
  z-index: 1;
  animation: topleft 5s infinite;
}
.imageB2-2 {
  position: absolute;

  transform: scale(0.4);
}
.imgB3-2 {
  position: absolute;
  top: -41px;
  left: 81px;

  z-index: 1;
  animation: topleft 5s infinite;
}
.imageB3-2 {
  position: absolute;
  transform: scale(0.4);
}
.imgB3-1a-2 {
  position: absolute;
  top: 128px;
  left: 124px;
  z-index: 1;
  animation: topleft 3s infinite;
}
.imageB3-1a-2 {
  position: absolute;

  transform: scale(0.3);
}

.imgB4-2 {
  position: absolute;
  top: 74px;
  left: 35px;
  z-index: 1;
  animation: topleft 5s infinite;
}
.imgB4-2-2 {
  position: absolute;
  top: -36px;
  left: -79px;
  z-index: 1;
  animation: topleft 5s infinite;
}
.imageB4-2 {
  position: absolute;

  transform: scale(0.4);
}
.imgB4-1a-2 {
  position: absolute;
  top: -38px;
  left: 127px;
  z-index: 1;
  animation: topleft 3s infinite;
}
.imageB4-1a-2 {
  position: absolute;
  transform: scale(0.1);
}
.imgB4-1b-2 {
  position: absolute;
  top: -80px;
  left: 0px;
  z-index: 1;
  animation: topleft 3s infinite;
}
.imgB4-2a-2 {
  position: absolute;
  bottom: 105px;
  left: -41px;
  z-index: 1;
  animation: topleft 5s infinite;
}
.imageB4-2a-2 {
  position: absolute;
  transform: scale(0.2);
}
.imgB4-2b-2 {
  position: absolute;
  top: 123px;
  left: -64px;
  z-index: 1;
  animation: topleft 5s infinite;
}
.imgB4-3a-2 {
  position: absolute;
  top: 0px;
  right: 95px;
  z-index: 1;
  animation: topleft 3s infinite;
}
.imageB4-3a-2 {
  position: absolute;
  transform: scale(0.35);
}
.imgB4-3b-2 {
  position: absolute;
  top: 135px;
  left: 89px;
  z-index: 1;
  animation: topleft 3s infinite;
}

.imgB5-2 {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}
.imageB5-2 {
  position: absolute;
  transform: scale(0.5);
  height: inherit;
  width: 100%;
  top: 0px;
  left: 0px;
  animation: mymove 5s infinite;
}
.imgB6-2 {
  position: absolute;
  transform: scale(0.9);
  top: 10px;
  left: 0px;
  width: 100%;
}
.imageB6-2 {
  position: absolute;
  height: inherit;
  top: 0px;
  left: 0px;
  width: 100%;
  animation: mymove 5s infinite;
}
@keyframes topleft {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes mymove {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
