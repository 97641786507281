.logo {
  max-width: 188px;
  height: inherit;
  cursor: pointer;
}
@media (max-width: 1481px) {
  .logo {
    width: 200px;
    height: 101px;
  }
}
